import { ComponentType } from '@angular/cdk/portal';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, firstValueFrom, Observable, tap } from 'rxjs';
import { CentralServerService } from 'services/central-server.service';
import { MessageService } from 'services/message.service';
import { SpinnerService } from 'services/spinner.service';
import { ChargingStationsAuthorizations, DialogParamsWithAuth } from 'types/Authorization';
import { BillingInvoice } from 'types/Billing';
import { ChargePointStatus, ChargingStation, Connector, OCPPGeneralResponse } from 'types/ChargingStation';
import { ActionResponse, Paging } from 'types/DataResult';
import { FilterParams } from 'types/GlobalType';
import { StartTransactionDialogData, Transaction } from 'types/Transaction';
import { Utils } from 'utils/Utils';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { User } from 'types/User';
import { ChargingStationsStartTransactionDialogComponent } from 'pages/charging-stations/charging-station-start-transaction/charging-stations-start-transaction-dialog-component';

@Component({
  selector: 'app-charging',
  templateUrl: 'charging.component.html',
  styleUrls: ['charging.component.scss']
})
export class ChargingComponent implements OnInit, OnDestroy {
  tagId: string;
  userId: string;
  transactionId: number | null = null;
  transactionDetails: Transaction;
  formattedDuration: string;
  energyInKWh: number;
  intervalId: any;
  isTransactionStopped = false;
  chargingStationId: string;
  connectorId: number;
  chargingStation: ChargingStation;
  public connectorStatus: string;
  public connectorExists = true;
  invoiceUrl: string;
  public isLoadingInvoice = true;
  connectorTest: Connector;
  transactoinStarted = false;
  user: User;
  private token: string;
  private authToken: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private centralServerService: CentralServerService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private dialog: MatDialog,

  ) { }
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
    });
    const token = this.decodeTokenHeader(this.token);
    const language = localStorage.getItem('language');
    const supportedLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'cs', 'cz'];
    const baseLanguage = language.split('-')[0];
    const languageToUse = supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
    this.translateService.use(languageToUse);
    const storedTransactionId = localStorage.getItem('transactionId');
    this.authToken = localStorage.getItem('token');
    this.tagId = localStorage.getItem('tagId');
    this.userId = localStorage.getItem('userId');
    this.transactionId = storedTransactionId ? parseInt(storedTransactionId, 10) : null;
    this.chargingStationId = token.chargingStationID;
    this.connectorId = Number(token.connectorID);
    if (this.transactionId) {
      this.getTransactionDetails();
    }
    this.loadChargingStation(this.chargingStationId, this.connectorId);
    this.intervalId = setInterval(() => {
      if (this.transactionId) {
        console.log('ChargingComponent ~ this.intervalId=setInterval ~ this.transactionId:', this.transactionId);
        this.loadChargingStation(this.chargingStationId, this.connectorId);
        this.getTransactionDetails();
      } else {
        return null;
      }
    }, 10000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  redirectToEv24(): void {
    window.location.href = 'https://www.ev24.io/support/';
  }

  public stopTransaction(): void {
    this.spinnerService.show();
    this.centralServerService.stopTransaction(this.transactionId).subscribe({
      next: (res: ActionResponse) => {
        this.spinnerService.hide();
        if (res.status === OCPPGeneralResponse.ACCEPTED) {
          console.log(`Transaction ${this.transactionId} stopped successfully.`);
          if (this.connectorExists) {
            this.isTransactionStopped = true;
          }
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }
          this.checkConnectorStatus();
        } else {
          console.error('Failed to stop transaction');
        }
      },
      error: (error) => {
        this.spinnerService.hide();
        console.error('Error stopping transaction:', error);
      }
    });
  }

  public ViewInvoice(): void {
    if (this.invoiceUrl) {
      window.open(this.invoiceUrl, '_blank');
    } else {
      console.log('Invoice URl nit found');
    }
  }

  public async getInvoiceByTransactionID(userID: string, transactionID: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.spinnerService.show();
      console.log('Checking for invoice ~ transactionID:', transactionID);
      const paging: Paging = { limit: 1000, skip: 0 };

      this.centralServerService.getInvoices({ userID }, paging).subscribe({
        next: (invoices) => {
          console.log('Received invoices:', invoices);
          if (invoices && invoices.result.length > 0) {
            let foundInvoice: BillingInvoice | undefined;
            for (const invoice of invoices.result) {
              const session = invoice.sessions.find(s => s.transactionID === transactionID);
              if (session) {
                foundInvoice = invoice;
                console.log('Invoice found:', foundInvoice);
                this.sendInvoiceToUser(foundInvoice);
                resolve(true);
                return;
              }
            }
          }
          console.log('No invoice found for this transaction ID.');
          resolve(false);
        },
        error: (error) => {
          console.error('Error fetching invoice:', error);
          resolve(false);
        }
      });
      this.spinnerService.hide();
    });
  }

  public logout() {
    this.centralServerService.logout().subscribe({
      next: () => {
        this.centralServerService.clearLoginInformation();
      },
      error: (error) => {
        this.centralServerService.clearLoginInformation();
      }
    });
  }

  // public getInvoiceByUserID(userID: string): void {
  //   this.spinnerService.show();
  //   console.log('ChargingComponent ~ this.centralServerService.getInvoices ~ userID:', userID);
  //   this.centralServerService.getInvoices({ userID }).subscribe({
  //     next: (invoices) => {
  //       console.log('ChargingComponent ~ this.centralServerService.getInvoices ~ invoices:', invoices);
  //       if (invoices && invoices.result.length > 0) {
  //         const invoice = invoices.result[0];
  //         console.log('Invoice:', invoice.sessions[0].transactionID);
  //         this.sendInvoiceToUser(invoice);
  //       } else {
  //         console.log('No invoices found for this user.');
  //       }
  //     },
  //     error: (error) => {
  //       console.error('Error fetching invoice:', error);
  //     }
  //   });
  //   this.spinnerService.hide();
  // }

  public async startTransactione(): Promise<void> {
    try {
      console.log('Starting transaction...');
      this.spinnerService.show();
      const response = await this.centralServerService.startTransaction(this.chargingStationId, this.connectorId, this.userId, this.tagId,).toPromise();

      if (response.status === OCPPGeneralResponse.ACCEPTED) {
        console.log('Transaction started successfully');
        await new Promise(resolve => setTimeout(resolve, 3000));
        let retryCount = 0;
        const maxRetries = 5;
        while (retryCount < maxRetries) {
          this.transactionId = await this.getUserTransactions();
          if (this.transactionId) {
            break;
          }
          retryCount++;
          await new Promise(resolve => setTimeout(resolve, 2000));
        }

        if (this.transactionId) {
          const transactionString = this.transactionId.toString();
          localStorage.setItem('transactionId', transactionString);
          this.getTransactionDetails();
        } else {
          console.error('Transaction ID is null or undefined after retries');
        }
      } else {
        console.error('Failed to start transaction');
      }
    } catch (error) {
      console.error('Error starting transaction:', error);
    } finally {
      this.spinnerService.hide();
    }
  }

  public async startTransaction(
    chargingStationsStartTransactionDialogComponent: ComponentType<ChargingStationsStartTransactionDialogComponent>,
    translateService: TranslateService,
    dialog: MatDialog,
    chargingStation = this.chargingStation,
    connector = this.connectorTest,
    refresh?: () => Observable<void>
  ): Promise<void> {
    if (this.chargingStation.inactive) {
      this.messageService.showErrorMessage(
        translateService.instant('chargers.action_error.transaction_start_title'),
        translateService.instant('chargers.action_error.transaction_start_title')
      );
      return;
    }
    // if (this.connectorTest.status === ChargePointStatus.UNAVAILABLE) {
    //   this.messageService.showErrorMessage(
    //     this.translateService.instant('chargers.action_error.transaction_start_title'),
    //     this.translateService.instant('chargers.action_error.transaction_start_not_available')
    //   );
    //   return;
    // }
    if (this.connectorTest.currentTransactionID) {
      this.messageService.showErrorMessage(
        this.translateService.instant('chargers.action_error.transaction_start_title'),
        this.translateService.instant('chargers.action_error.transaction_in_progress')
      );
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.minWidth = '40vw';
    dialogConfig.panelClass = '';

    // Build dialog data
    const dialogData: DialogParamsWithAuth<StartTransactionDialogData, ChargingStationsAuthorizations> = {
      dialogData: {
        id: chargingStation.id,
        chargingStation,
        connector,
      },
    };
    dialogConfig.data = dialogData;

    try {
      const dialogRef = this.dialog.open(chargingStationsStartTransactionDialogComponent, dialogConfig);

      // Await the result from the dialog
      const result = await dialogRef.afterClosed().toPromise();

      if (result) {
        const user = await this.getUserByID(this.userId).toPromise();
        const userFullName = user.fullName;

        this.startTransactionForUser(
          this.chargingStation,
          this.connectorTest,
          userFullName,
          this.userId,
          this.tagId,
          null,
          this.messageService,
          this.translateService,
          this.centralServerService,
          this.router,
          this.spinnerService,
          refresh
        );
      }
    } catch (error) {
      console.log('ChargingComponent ~ error:', error);
    }
  }

  public async getUserTransactions(): Promise<number | null> {
    try {
      const userId = localStorage.getItem('userId');
      const filterParams: FilterParams = { UserID: userId };
      console.log('ChargingComponent ~ getUserTransactions ~ userId:', userId);
      const transactionResult = await firstValueFrom(this.centralServerService.getActiveTransactions(filterParams));
      console.log('ChargingComponent ~ getUserTransactions ~ filterParams:', filterParams);
      if (transactionResult.result.length > 0) {
        console.log('Transaction ID retrieved:', transactionResult.result[0].id);
        return transactionResult.result[0].id;
      } else {
        console.log('No active transactions found for user');
        return null;
      }
    } catch (err) {
      console.error('Error retrieving transactions:', err);
      return null;
    }
  }

  public getTransactionDetails(): void {
    this.centralServerService.getTransaction(this.transactionId).subscribe({
      next: (transaction) => {
        this.transactionDetails = transaction;
        console.log('ChargingComponent ~ this.centralServerService.getTransaction ~ this.transactionDetails:', this.transactionDetails);
        this.formattedDuration = this.convertSecondsToMinutesAndSeconds(transaction.currentTotalDurationSecs);
        this.energyInKWh = this.convertWattsToKWh(transaction.currentTotalConsumptionWh);
      },
      error: (error) => {
        console.error('Error fetching transaction details:', error);
      }
    });
  }

  public onStartTransactionButtonClick(): void {
    this.startTransaction(ChargingStationsStartTransactionDialogComponent, this.translateService, this.dialog);
  }

  private getUserByID(userId: string): Observable<User> {
    return this.centralServerService.getUser(userId).pipe(
      tap((user) => {
        this.user = user;
      }),
      catchError((error) => {
        console.error('Error fetching user:', error);
        return EMPTY;
      })
    );
  }


  private startTransactionForUser(
    chargingStation: ChargingStation,
    connector: Connector,
    userFullName: string,
    userID: string,
    visualTagID: string,
    carID: string | null,
    messageService: MessageService,
    translateService: TranslateService,
    centralServerService: CentralServerService,
    router: Router,
    spinnerService: SpinnerService,
    refresh?: () => Observable<void>
  ): void {
    if (!visualTagID) {
      this.messageService.showErrorMessage(
        this.translateService.instant('chargers.start_transaction_missing_active_tag', {
          chargeBoxID: chargingStation.id,
          userName: userFullName,
        })
      );
      return;
    }
    this.spinnerService.show();
    this.centralServerService.startTransaction(chargingStation.id, connector.connectorId, userID, this.tagId, carID).subscribe({
      next: async (startTransactionResponse: ActionResponse) => {
        this.spinnerService.hide();
        if (startTransactionResponse.status === OCPPGeneralResponse.ACCEPTED) {
          await new Promise(resolve => setTimeout(resolve, 3000));
          let retryCount = 0;
          const maxRetries = 5;
          while (retryCount < maxRetries) {
            this.transactionId = await this.getUserTransactions();
            if (this.transactionId) {
              break;
            }
            retryCount++;
            await new Promise(resolve => setTimeout(resolve, 2000));
          }

          if (this.transactionId) {
            const transactionString = this.transactionId.toString();
            localStorage.setItem('transactionId', transactionString);
            this.getTransactionDetails();
          } else {
            console.error('Transaction ID is null or undefined after retries');
          }
          this.transactoinStarted = true;
          this.messageService.showSuccessMessage(
            this.translateService.instant('chargers.start_transaction_success', { chargeBoxID: chargingStation.id })
          );
          if (refresh) {
            refresh().subscribe();
          }
        } else {
          this.transactoinStarted = false;
          this.messageService.showErrorMessage(
            this.translateService.instant('chargers.start_transaction_error')
          );
        }
      },
      error: (error) => {
        this.spinnerService.hide();
        Utils.handleHttpError(error, router, messageService, centralServerService, 'chargers.start_transaction_error');
      }
    });
  }

  private async loadChargingStation(entityId: string, connectorId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.centralServerService.getChargingStation(entityId).subscribe({
        next: (chargingStation: ChargingStation) => {
          this.chargingStation = chargingStation;
          console.log('ChargingComponent ~ this.centralServerService.getChargingStationQr ~ chargingStation:', chargingStation);
          this.connectorExists = chargingStation.connectors.some((connector) => connector.connectorId === connectorId);
          this.connectorId = connectorId;
          this.connectorTest = Utils.getConnectorFromID(chargingStation, connectorId);
          if (this.connectorTest) {
            const statusKey = `connectorStatus.${this.connectorTest.status.toUpperCase()}`;
            this.translateService.get(statusKey).subscribe((translatedStatus: string) => {
              this.connectorStatus = translatedStatus || this.connectorTest.status;
              this.connectorExists = true;
            });
          } else {
            this.connectorExists = false;
            this.connectorStatus = '';
          }

          if (!this.connectorExists) {
            this.spinnerService.hide();
          }
          resolve();
        },
        error: (error) => {
          console.error('Failed to load charging station:', error);
          this.spinnerService.hide();
          this.connectorExists = false;
          this.connectorStatus = '';
          reject(error);
        },
      });
    });
  }

  private pollForInvoice(userID: string, transactionID: number): void {
    let retries = 0;
    const maxRetries = 10;
    const pollInterval = 5000;
    this.isLoadingInvoice = true;

    const invoicePolling = setInterval(() => {
      if (retries >= maxRetries) {
        clearInterval(invoicePolling);
        this.isLoadingInvoice = false;
        console.error('Max retries reached. No invoice found.');
        this.spinnerService.hide();
        return;
      }

      this.getInvoiceByTransactionID(userID, transactionID).then((invoiceFound) => {
        if (invoiceFound) {
          clearInterval(invoicePolling);
          this.isLoadingInvoice = false;
          this.spinnerService.hide();
          this.logout();
        }
      });

      retries++;
    }, pollInterval);
  }

  private checkConnectorStatus(): void {
    const checkInterval = setInterval(() => {
      this.loadChargingStation(this.chargingStationId, this.connectorId).then(() => {
        if (this.connectorTest.status === 'Available') {
          clearInterval(checkInterval);
          this.pollForInvoice(this.transactionDetails.user.id, this.transactionDetails.id);
        } else {
          console.log('Connector status is still not available. Checking again...');
        }
      }).catch(error => {
        console.error('Error loading charging station:', error);
      });
    }, 5000);
  }


  private sendInvoiceToUser(invoice: any): void {
    this.spinnerService.show();
    this.centralServerService.getInvoice(invoice.id).subscribe({
      next: (invoiceBlob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const invoiceData = JSON.parse(reader.result as string);
          this.invoiceUrl = invoiceData.downloadUrl;
          const userEmail = this.transactionDetails.user.email;
          console.log(`Simulating sending invoice to ${userEmail} with download link: ${this.invoiceUrl}`);
        };
        reader.onerror = (error) => {
          console.error('Error reading the invoice blob:', error);
        };
        reader.readAsText(invoiceBlob);
      },
      error: (error) => {
        console.error('Error fetching invoice:', error);
      }
    });
    localStorage.removeItem('transactionId');
    localStorage.removeItem('tagId');
    localStorage.removeItem('userId');
    localStorage.removeItem('SessionToken');
    this.spinnerService.hide();
    this.logout();
  }

  private convertSecondsToMinutesAndSeconds(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  }

  private convertWattsToKWh(watts: number): number {
    return watts / 1000;
  }

  private decodeTokenHeader(token: string): any {
    console.log('decodeTokenHeader called with token:', token);

    // Check if the token is non-empty and does not have periods (i.e., no standard JWT format)
    if (!token || token.indexOf('.') === -1) {
      console.log('Token does not contain periods. Treating token as a single part.');

      try {
        // Handle single-part token
        const headerBase64Url = token;

        // Convert base64Url to base64
        const base64UrlToBase64 = (base64Url: string): string => base64Url.replace(/-/g, '+').replace(/_/g, '/');

        // Decode base64 to string
        const decodeBase64Url = (base64Url: string): string => {
          let base64 = base64UrlToBase64(base64Url);
          while (base64.length % 4 !== 0) {
            base64 += '=';
          }
          return atob(base64);
        };

        // Decode and parse the header
        const header = decodeBase64Url(headerBase64Url);
        console.log('Decoded header:', header);
        return JSON.parse(header);
      } catch (error) {
        console.error('Error decoding token header:', error);
        throw new Error('Failed to decode token header');
      }
    } else {
      console.error('Invalid token format. Expected at least one part but got:', token);
      throw new Error('Invalid token format');
    }
  }

}
